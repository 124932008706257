<template>
   <div>
      <Notification ref="user_error_notification" type="error" message="Error en usuario y/o contraseña" />
      <div class="d-flex justify-center align-center" style="width: 100%; height: calc(100vh - 92px)">
         <v-card elevation="2" width="90%" max-width="600px" :loading="isloading">
            <v-form class="pa-6">
               <v-text-field
                  label="Usuario"
                  v-model="username"
                  :error-messages="usernameErrors"
                  required
                  @input="$v.username.$touch()"
                  @blur="$v.username.$touch()"
               ></v-text-field>
               <v-text-field
                  label="Contraseña"
                  v-model="password"
                  :error-messages="passwordErrors"
                  required
                  @keydown.enter="submit"
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                  :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_password ? 'text' : 'password'"
                  @click:append="show_password = !show_password"
               ></v-text-field>
               <div class="d-flex justify-center align-center">
                  <v-btn color="primary" light class="mr-4" @click="submit" :loading="isloading">Iniciar sesión</v-btn>
                  <v-btn color="secondary" light @click="clear" x-small icon>
                     <v-icon dense>mdi-eraser</v-icon>
                  </v-btn>
               </div>
            </v-form>
         </v-card>
      </div>
   </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
   mixins: [validationMixin],
   validations: {
      username: { required },
      password: { required },
   },
   data: () => ({
      username: "",
      password: "",
      show_password: false,
      isloading: false,
   }),
   computed: {
      usernameErrors() {
         const errors = [];
         if (!this.$v.username.$dirty) return errors;
         !this.$v.username.required && errors.push("El nombre de usuario es requerido");
         return errors;
      },
      passwordErrors() {
         const errors = [];
         if (!this.$v.password.$dirty) return errors;
         !this.$v.password.required && errors.push("La contraseña es requerida");
         return errors;
      },
   },
   methods: {
      async submit() {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = await this.$store.dispatch("login", {
                  username: this.username,
                  password: this.password,
               });
               if (res == true) {
                  this.$router.push({
                     name: "Dashboard",
                  });
               } else {
                  throw res;
               }
            } else {
               throw "error en formulario";
            }
         } catch (error) {
            this.$refs.user_error_notification.Show();
            this.clear();
         } finally {
            this.isloading = false;
         }
      },
      clear() {
         this.$v.$reset();
         this.username = "";
         this.password = "";
      },
   },
};
</script>
